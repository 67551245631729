import { Component, Prop, Mixins } from '@zento-lib/components';
import type { IZentoTheme } from '@zento-common/lib/config/types/zento/theme';

import { FormField } from './FormField';
import { ValidatorMinLength, ValidatorMaxLength, ValidatorPattern } from './types';
import { ValidationMechanisms } from './validation/mechanisms';
import { ValidationMessage } from './Message';
import type { IInput } from './Input.d';
import style from './style.scss';

export type InputPropagationEventType = 'change' | 'input' | 'blur';

export type PatternType =
  | 'email'
  | 'name'
  | 'phone'
  | 'password'
  | 'street'
  | 'zipcode'
  | 'alphanumeric'
  | 'alphanumspaces'
  | 'alphanumspecialchar'
  | RegExp;

@Component({})
export class Input extends Mixins<FormField<string, InputPropagationEventType, IInput, any>>(FormField) {
  // Unique input id counter
  private static UNIQUE_LABEL_ID = -1;

  // Current instance unique input id (only used when labelFor is not defined)
  private uniqueLabelId: number;

  /**
   * Check if the validator minLength exists in the context of current field instance
   */
  protected static validatorMinLengthExtraction(instance: Input): ValidatorMinLength | null {
    if ('minLength' in instance && instance.minLength !== undefined) {
      const value =
        typeof instance.minLength === 'string' ? parseInt(instance.minLength || '', 10) : instance.minLength;

      if (isNaN(value)) {
        throw new Error(
          `Form: Missing minLength validation rule value for field ${this.name}.` +
            `Please consider adding a new data property with name ${this.name} and desired value`,
        );
      }

      return { value };
    }

    return null;
  }

  /**
   * Check if the validator maxLength exists in the context of current field instance
   */
  protected static validatorMaxLengthExtraction(instance: Input): ValidatorMaxLength | null {
    if ('maxLength' in instance && instance.maxLength !== undefined) {
      const value =
        typeof instance.maxLength === 'string' ? parseInt(instance.maxLength || '', 10) : instance.maxLength;

      if (isNaN(value)) {
        throw new Error(
          `Form: Missing maxLength validation rule value for field ${this.name}.` +
            `Please consider adding a new data property with name ${this.name} and desired value`,
        );
      }

      return { value };
    }

    return null;
  }

  protected static validatorPatternExtraction(instance: Input): ValidatorPattern | null {
    if ('pattern' in instance && instance.pattern !== undefined) {
      const value = instance.pattern;

      return { value, subRule: typeof value === 'string' && !value.startsWith('^') ? value : 'other' };
    }

    return null;
  }

  /**
   * Record of validation rule names to validation parameters extraction functions
   */
  protected validationRules = {
    [FormField.RequiredRuleName]: FormField.validatorRequiredExtraction,
    minLength: Input.validatorMinLengthExtraction,
    maxLength: Input.validatorMaxLengthExtraction,
    pattern: Input.validatorPatternExtraction,
  };

  protected validationMechanisms = {
    [FormField.RequiredRuleName]: ValidationMechanisms.string.required,
    minLength: ValidationMechanisms.string.minLength,
    maxLength: ValidationMechanisms.string.maxLength,
    pattern: ValidationMechanisms.string.pattern,
  };

  /**
   * Input type property
   */
  @Prop({ type: String, default: 'text' })
  type?: 'text' | 'search' | 'password' | 'email' | 'tel' | 'hidden' | 'url' | 'file' | 'date' | 'textarea';

  /**
   * Input minLength property
   */
  @Prop({ type: [Number, String] }) minLength: number | string;

  /**
   * Input maxLength property
   */
  @Prop({ type: [Number, String] }) maxLength?: number | string;

  /**
   * Input placeholder property
   */
  @Prop({ type: String, default: '', required: false }) placeholder?: string;

  /**
   * Input pattern property for validations
   */
  @Prop({ type: String }) pattern?: PatternType;

  /**
   * Input label
   */
  @Prop({ type: String, default: '' }) labelFor?: string;

  /**
   * Label style
   */
  @Prop({ type: String, default: '' }) labelStyle?: IZentoTheme['labelStyle'];

  /**
   * Input autofocus
   */
  @Prop({ type: Boolean, default: false }) autofocusInput?: boolean;

  /**
   * Input autocomplete behavior
   * (https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete)
   */
  @Prop({ type: String, default: 'on' }) autocomplete?: string;

  /**
   * Input custom error property
   */
  @Prop({ type: String, default: '', required: false }) customMessage?: string;

  /**
   * Determines data test id used for qa test
   */
  @Prop({ type: String, default: '' }) dataTestId?: string;

  /**
   * Determines if input is editable
   */
  @Prop({ type: Boolean, default: false }) readOnly?: boolean;

  data() {
    return {
      value: this.getFieldValue(), // Take the initial state value
      animateLabel: false,
      typingTimer: null,
      doneTypingInterval: 350,
      extractedRules: {},
    };
  }

  beforeMount() {
    // Compute unique label id for each Input for cases when labelFor is not specified
    this.uniqueLabelId = Input.UNIQUE_LABEL_ID++;
  }

  updated() {
    if (this.autofocusInput) {
      (this.$refs.input as HTMLFormElement).focus();
    }
  }

  get inputId() {
    return this.labelFor || `uid-${this.uniqueLabelId}`;
  }

  get computedLabelStyle() {
    return this.labelStyle || this.extended.$config.zento.theme.labelStyle;
  }

  get labelPosition() {
    switch (true) {
      case this.computedLabelStyle.includes('top'):
        return style.labelTop;

      // case this.computedLabelStyle.includes('left'):
      //   return style.labelLeft;

      // case this.computedLabelStyle.includes('right'):
      //   return style.labelRight;

      // case this.computedLabelStyle.includes('bottom'):
      //   return style.labelBottom;

      default:
        return '';
    }
  }

  public render() {
    const pristine = this.isPristine();
    const valid = this.isValid();
    const hasValidation = this.activeRules.length > 0;
    const props: Record<string, any> = {
      value: this.$data.value,
      onInput: this.handleInput,
      onChange: this.handleChange,
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      onFocusOut: this.handleFocusOut,
      onPaste: this.handleInput,
      autocomplete: this.autocomplete,
      placeholder: this.placeholder,
      id: this.inputId,
      'data-testId': this.dataTestId,
      readonly: this.readOnly,
      ref: this.type === 'textarea' ? 'textarea' : 'input',
    };

    return (
      <ValidationMessage type='string' state={this.validationState} validationParams={this.rules}>
        {this.computedLabelStyle === 'classic' ? (
          <label for={this.inputId} key='beforeLabel'>
            <slot name='label' />
            {this.required ? <em>*</em> : null}
          </label>
        ) : null}
        <div
          class={{
            [style.inputBox]: true,
            [style.inputValid]: !pristine && valid && hasValidation,
            [style.inputError]: (!pristine && !valid && hasValidation) || this.customMessage !== '',
            [style.inputWrapper]:
              (this.computedLabelStyle.includes('animated') || this.computedLabelStyle.includes('static')) &&
              (this.$data.value || this.$data.animateLabel),
            [style.file]: this.type === 'file',
            [style.date]: this.type === 'date',
          }}
          onClick={this.handleLabelAnimation}
          data-input='input-box'
          data-error={(!pristine && !valid && hasValidation) || this.customMessage !== ''}>
          <div class={style.inputContent} data-input='input-content'>
            <slot name='before' />
            {this.computedLabelStyle !== 'classic' ? (
              <label
                for={this.inputId}
                class={{
                  [style.beforeLabel]: true,
                  [style.animateLabel]: this.computedLabelStyle.includes('animated'),
                  [style.staticLabel]: this.computedLabelStyle.includes('static'),
                  [this.labelPosition]: true,
                  [style.textareaLabel]: this.type === 'textarea',
                }}
                key='beforeLabel'>
                <slot name='label' />
                {this.required ? <em>*</em> : null}
              </label>
            ) : null}
            {this.type === 'textarea' ? (
              <textarea
                class={{
                  [style.input]: true,
                  [style.inputData]: this.$data.value && this.$data.value !== '',
                  [style.textarea]: true,
                }}
                {...props}
              />
            ) : (
              <input
                type={this.type}
                class={{ [style.input]: true, [style.inputData]: this.$data.value && this.$data.value !== '' }}
                {...props}
              />
            )}
            <i></i>
            <slot name='inside' />
          </div>

          {pristine || valid ? (
            <span class={style.helperText} data-slot='helper-text'>
              <slot name='after' />
            </span>
          ) : null}

          {this.customMessage ? (
            <div class={style.errorMessage}>{this.getTranslation({ id: this.customMessage })}</div>
          ) : null}
        </div>
      </ValidationMessage>
    );
  }

  /**
   * Handle input events
   */
  private handleInput(ev: InputEvent) {
    clearTimeout(this.$data.typingTimer);
    this.$data.typingTimer = setTimeout(() => {
      // Cache the value in local component state
      const target = ev.target;
      if (target && 'value' in target) {
        this.$data.value = (target as any).value;
      }

      this.propagateValue(ev.type as InputPropagationEventType);

      // Propagate event to other interested parties
      this.propagateEvent(ev);
    }, this.$data.doneTypingInterval);
  }

  /**
   * Handle blur events
   */
  private handleBlur(ev: Event) {
    // Cache the value in local component state
    const target = ev.target;
    if (target && 'value' in target) {
      this.$data.value = (target as any).value;
    }

    this.propagateValue(ev.type as InputPropagationEventType);

    // Propagate event to other interested parties
    this.propagateEvent(ev);
  }

  /**
   * Handle change events
   */
  private handleChange(ev: Event) {
    this.propagateValue(ev.type as InputPropagationEventType);

    // Propagate event to other interested parties
    this.propagateEvent(ev);
  }

  /**
   * Handle focus events
   */
  private handleFocus(ev: Event) {
    this.propagateValue(ev.type as InputPropagationEventType);

    // Propagate event to other interested parties
    this.propagateEvent(ev);
  }

  /**
   * Handle label animation on click
   */
  private handleLabelAnimation() {
    this.$data.animateLabel = true;
  }

  /**
   * Handle focus out events
   */
  private handleFocusOut(ev: Event) {
    this.$data.animateLabel = false;

    // Propagate event to other interested parties
    this.propagateEvent(ev);
  }
}
